import React from "react";
import { SeoContainer } from "./styles";

const SEO = () => {
      return (
            <>
                  <SeoContainer>
                        <h1>Technical Associates Services P. Ltd.</h1>
                  </SeoContainer>
            </>
      );
};

export default SEO;
