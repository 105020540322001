import React from 'react'

const DroprightIcon = () => {
  return (
    <>
      <i className="ri-arrow-drop-right-line"></i>
    </>
  )
}

export default DroprightIcon
