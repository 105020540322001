import styled from "styled-components";

export const ProductImage = styled.div`
      height: 100%;
      width: 100%;
      display: flex;
      align-items: baseline;
      justify-content: center;
      max-width: 520px;
      margin: auto;

      img {
            object-fit: contain;
      }
`;
