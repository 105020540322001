import styled from "styled-components";

export const PolicyContent = styled.div`
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      padding: 20px;
      border-radius: 10px;
      margin-bottom: 8rem;
      margin-top: -4rem;
      z-index: 999;
      position: relative;

      .spinner-border {
            border-width: 1.5px;
      }
`;
