export const overviews = [
  {
    id: 1,
    title: 'THERMAX TCA',
    subTitle: 'Providing the Best Sustainable Heating Solution With',
    image: '/images/Associations-Logo1.png',
    summary:
      'We are the authorized distributors of Thermax Global providing the best Domestic and Industrial heating  solution and boilers in Nepal. With Thermax’s legacy in heat transfer technology, environmental engineering and in-house capability of manufacturing almost 35% of power plant equipment, we are best placed to execute projects on turnkey basis.',
    slug: '',
  },
  {
    id: 2,
    title: 'GRUNDFOS ',
    subTitle: 'When you need water, we get it to you efficiently',
    image: '',
    summary:
      'Whatever your application, our innovative, high-efficiency, energy-saving pumps and water solutions help you provide comfort, deliver drinking water, remove wastewater or water crops all over the world – to name just some areas of our expertise.',
    slug: '',
  },
]
