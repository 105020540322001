export const products = [
      {
            id: 2,
            title: "Water & Space Heating Systems",
            summary: "We go beyond testing, inspecting and certifying products; we are a Total Quality Assurance provider to industries worldwide.…",
            image: "/images/product-1.png",
            slug: "project-1",
      },
      {
            id: 2,
            title: "Water Treatment system ",
            summary: "We go beyond testing, inspecting and certifying products; we are a Total Quality Assurance provider to industries worldwide.…",
            image: "/images/product-2.jpg",
            slug: "project-2",
      },
      {
            id: 3,
            title: "Boilers",
            summary: "We go beyond testing, inspecting and certifying products; we are a Total Quality Assurance provider to industries worldwide.…",
            image: "/images/product-3.jpg",
            slug: "project-3",
      },
      {
            id: 4,
            title: "waste & effluent treatment",
            summary: "We go beyond testing, inspecting and certifying products; we are a Total Quality Assurance provider to industries worldwide.…",
            image: "/images/product-4.png",
            slug: "project-4",
      },
];
export const blogs = [
      {
            id: 1,
            day: 2,
            month: "Jan",
            year: 2024,
            image: "/images/img-5.jpg",
            title: "Laing O’Rourke: Moves, projects and bids",
            slug: "blog-1",
      },
      {
            id: 2,
            day: 10,
            month: "Fab",
            year: 2024,
            image: "/images/img-6.jpg",
            title: "How to turn Victorian gasholders apartments",
            slug: "blog-2",
      },
      {
            id: 3,
            day: 20,
            month: "Oct",
            year: 2024,
            image: "/images/img-7.jpg",
            title: "CITB appoints Peter Lauener as new chairman",
            slug: "blog-3",
      },

      {
            id: 4,
            day: 2,
            month: "Jan",
            year: 2024,
            image: "/images/img-5.jpg",
            title: "Laing O’Rourke: Moves, projects and bids",
            slug: "blog-1",
      },
      {
            id: 5,
            day: 10,
            month: "Fab",
            year: 2024,
            image: "/images/img-6.jpg",
            title: "How to turn Victorian gasholders apartments",
            slug: "blog-2",
      },
];
export const testimonials = [
      {
            description:
                  "Hiday Motors needed to build a brand new 28,000 sf facility that would both meet our needs and comply with GMs standards - which seemed daunting, to say the least. Industify alleviated all of our concerns and communicated with us every step of the way. We have received dozens of compliments from our customers, and our employees love the new store!",
            name: "Steve Lehman",
            post: "CEO of Hiday Motors",
      },
];

export const banners = [
      {
            image: "/images/banner-4.jpg",
            category: "industry",
            title: "Energy & Commodities",
            description:
                  "Achieving this successfully calls for a vision of what can be and a distinct ability to identify opportunities for salvaging elements.",
      },
      {
            image: "/images/img-5.jpg",
            category: "Technology",
            title: "Leed & Green Building",
            description:
                  "In addition to our large, ground up projects, we accommodate small to modest sized projects for our customers.",
      },
      {
            image: "/images/img-1.jpg",
            category: "Modern",
            title: "Small Projects & Maintenance",
            description:
                  "Here at Industify, we take the same approach to green building that we take to every other aspect of our business.",
      },
];

export const footerMenus = [
      {
            id: 1,
            title: "FAQs",
            slug: "/faqs",
      },
      {
            id: 5,
            title: "contact us",
            slug: "/contact-us",
      },
      {
            id: 2,
            title: "News & Blog",
            slug: "/blogs",
      },
      {
            id: 6,
            title: "Privacy Policy",
            slug: "/privacy-policy",
      },
      {
            id: 3,
            title: "Terms & Condition",
            slug: "/terms-and-condition",
      },
];

export const footerProducts = [
      {
            id: 1,
            title: "Thermax Boilers",
            sulg: "/thermax-boilers",
      },
      {
            id: 2,
            title: "Water Pumps",
            sulg: "/water-pumps",
      },
      {
            id: 3,
            title: "Compressed Air System",
            sulg: "/compressed-air-system",
      },
      {
            id: 4,
            title: "Fire Fighting System",
            sulg: "/fire-fighting-system",
      },
      {
            id: 5,
            title: "Oxygen & Nitrogen Generators",
            sulg: "/oxygen-and-nitrogen-generators",
      },
];

export const footerData = [
      {
            description:
                  "Technical Associates Services (TAS) aim to provide cost effective engineering solutions to people and industries in Nepal.",
            address: "Aspen Marg, Maitighar, St. Xavier College Rd, Kathmandu, Nepal – 44600",
            phoneNumber: "01-5339555",
            email: "info@tas.com.np",
      },
];

export const testimonialsCards = [
      {
            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci nihil deleniti ad fugit ducimus quia rem maxime ipsum est culpa expedita aperiam omnis veritatis, error, eos facere minus debitis esse?",
            image: "/images/banner-1.jpg",
            name: "David Warner",
            post: "marketing manager",
      },
      {
            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci nihil deleniti ad fugit ducimus quia rem maxime ipsum est culpa expedita aperiam omnis veritatis, error, eos facere minus debitis esse?",
            image: "/images/banner-1.jpg",
            name: "melinda",
            post: "marketing manager",
      },
      {
            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci nihil deleniti ad fugit ducimus quia rem maxime ipsum est culpa expedita aperiam omnis veritatis, error, eos facere minus debitis esse?",
            image: "/images/img-1.jpg",
            name: "David Warner",
            post: "marketing manager",
      },
      {
            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci nihil deleniti ad fugit ducimus quia rem maxime ipsum est culpa expedita aperiam omnis veritatis, error, eos facere minus debitis esse?",
            image: "/images/img-2.jpg",
            name: "David Warner",
            post: "marketing manager",
      },
];

export const distributors = [
      {
            id: 1,
            logo: "/images/Associations-Logo1.png",
      },
      {
            id: 2,
            logo: "/images/Associations-Logo2.png",
      },
      {
            id: 3,
            logo: "/images/Associations-Logo3.png",
      },
      {
            id: 4,
            logo: "/images/Associations-Logo4.png",
      },

      {
            id: 5,
            logo: "/images/Associations-Logo5.png",
      },
      {
            id: 6,
            logo: "/images/Associations-Logo6.png",
      },
      {
            id: 7,
            logo: "/images/Associations-Logo7.png",
      },
      {
            id: 8,
            logo: "/images/Associations-Logo8.png",
      },

      {
            id: 9,
            logo: "/images/Associations-Logo9.png",
      },
      {
            id: 10,
            logo: "/images/Associations-Logo10.png",
      },
      {
            id: 11,
            logo: "/images/Associations-Logo11.png",
      },
      {
            id: 12,
            logo: "/images/Associations-Logo12.png",
      },

      {
            id: 13,
            logo: "/images/Associations-Logo13.png",
      },
      {
            id: 14,
            logo: "/images/Associations-Logo14.png",
      },
      {
            id: 15,
            logo: "/images/Associations-Logo15.png",
      },
      {
            id: 16,
            logo: "/images/Associations-Logo16.png",
      },
];

export const associations = [
      {
            id: 1,
            logo: "/images/microlife-2.png",
      },
      {
            id: 2,
            logo: "/images/Philips-1-1.png",
      },
      {
            id: 3,
            logo: "/images/GE.png",
      },
      {
            id: 4,
            logo: "/images/microlife-2.png",
      },
      {
            id: 5,
            logo: "/images/3M-2.png",
      },
];

export const faqsData = [
      {
            eventKey: 0,
            title: "TAS overview",
            body: " At Amwerk, we value a culture of collaboration and service. We’re passionate about serving our clients to the fullest, while continuously growing, learning, and innovating. With competencies across multiple industries, we offer our customers a more reliable supply chain and confidence in the long life of their program.",
      },

      {
            eventKey: 1,
            title: "How do we manage quality assurance?",
            body: " At Amwerk, we value a culture of collaboration and service. We’re passionate about serving our clients to the fullest, while continuously growing, learning, and innovating. With competencies across multiple industries, we offer our customers a more reliable supply chain and confidence in the long life of their program.",
      },
      {
            eventKey: 2,
            title: "How do we manage quality assurance?",
            body: " At Amwerk, we value a culture of collaboration and service. We’re passionate about serving our clients to the fullest, while continuously growing, learning, and innovating. With competencies across multiple industries, we offer our customers a more reliable supply chain and confidence in the long life of their program.",
      },
      {
            eventKey: 3,
            title: "How do we manage quality assurance?",
            body: " At Amwerk, we value a culture of collaboration and service. We’re passionate about serving our clients to the fullest, while continuously growing, learning, and innovating. With competencies across multiple industries, we offer our customers a more reliable supply chain and confidence in the long life of their program.",
      },
];


export const serviceCard = [
      {
            image: "/images/banner-3.jpg",
            title: "Annual Service Contract (ASC)",
            description:
                  " We have a team of highly trained and qualified engineers to take care of your services and maintenance needs for all our products. We offer packaged ASC, which includes Breakdown calls, regular maintenance and yearly overhauling.",
      },
      {
            image: "/images/banner-1.jpg",
            title: "Opearation & Maintenance Contract (O&M)",
            description:
                  "We offer operation & maintenance services for Medical Gas Pipeline Systems and PSA Type Oxygen Concentrators.",
      },
];

export const solutionsData = [
      {
            id: 1,
            itemName: "Hotels",
            itemList: [
                  {
                        id: 1,
                        listName: "Central Water and Space Heating products & Systems",
                  },
                  {
                        id: 2,
                        listName: "Pumps for Water Transfer, Submersible Pumps, Air conditioning Pumps, Hot water circulation pumps, Pressure Boosting pumps, Water Treatment pumps, gardening pumps, waste water pumps, rain water pumps.",
                  },
                  {
                        id: 3,
                        listName: "Fire Fighting Systems",
                  },
                  {
                        id: 4,
                        listName: "Water Treatment Systems.",
                  },
                  {
                        id: 5,
                        listName: "Sewage and Waste Water Treatment.",
                  },
                  {
                        id: 6,
                        listName: "Water & Oil Flow meters.",
                  },
                  {
                        id: 7,
                        listName: "Swimming Pool Filters, Pumps and accessories.",
                  },
                  {
                        id: 8,
                        listName: "Fountain & Water Features.",
                  },
                  {
                        id: 9,
                        listName: "Sprinklers and Irrigation System.",
                  },
            ],
      },
      {
            id: 2,
            itemName: "Hospitals",
            itemList: [
                  {
                        id: 1,
                        listName: "Medical Gas Pipeline Systems",
                  },
                  {
                        id: 2,
                        listName: "PSA Medical Oxygen Generators.",
                  },
                  {
                        id: 3,
                        listName: "Modular Operation Theatres",
                  },
                  {
                        id: 4,
                        listName: "Pneumatic Tube Systems.",
                  },
                  {
                        id: 5,
                        listName: "Hospital Curtain Track Systems",
                  },
                  {
                        id: 6,
                        listName: "Wall Guards and Corner Guards",
                  },
                  {
                        id: 7,
                        listName: "Medical Waste Management",
                  },
                  {
                        id: 8,
                        listName: "Central Water and Space Heating products & Systems",
                  },
                  {
                        id: 9,
                        listName: "Pumps for Water Transfer, Submersible Pumps, Air conditioning Pumps, Hot water circulation pumps, Pressure Boosting pumps, Water Treatment pumps, gardening pumps, waste water pumps, rain water pumps.",
                  },
                  {
                        id: 10,
                        listName: "Water Treatment Systems.",
                  },
                  {
                        id: 11,
                        listName: "Sewage and Waste Water Treatment.",
                  },
                  {
                        id: 12,
                        listName: "Swimming Pool Filters, Pumps and accessories.",
                  },
                  {
                        id: 13,
                        listName: "Fountain & Water Features.",
                  },
            ],
      },
      {
            id: 3,
            itemName: "Fire Fighting Systems",
            itemList: [
                  {
                        id: 1,
                        listName: "Central Water and Space Heating products & Systems",
                  },
                  {
                        id: 2,
                        listName: "Pumps for Water Transfer, Submersible Pumps, Air conditioning Pumps, Hot water circulation pumps, Pressure Boosting pumps, Water Treatment pumps, gardening pumps, waste water pumps, rain water pumps.",
                  },
                  {
                        id: 3,
                        listName: "Fire Fighting Systems",
                  },
                  {
                        id: 4,
                        listName: "Water Treatment Systems.",
                  },
                  {
                        id: 5,
                        listName: "Sewage and Waste Water Treatment.",
                  },
            ],
      },
];
export const waterAndSpaceHeatingaData = {
      description:
            "<p>Water heating is a heat transfer process that uses an energy source to heat water above its initial temperature. Typical domestic uses of hot water include cooking, cleaning, bathing, and space heating. In industry, hot water and water heated to steam have many uses.</p><p>Electric space and water heating systems are second only to natural gas as the preferred primary heating choice for domestic, commercial and industrial premises in Nepal.</p>",

      image: "/images/product-1.png",
};

export const waterPumpsData = {
      description:
            "<p>Technical Associates Services (TAS) is an authorized distributor of world-renowned Danish Pump manufacturer Grundfos Ltd. in Nepal. Grundfos pioneer in providing solutions to the World and climate challenges in order to improve the quality of life of the people.</p>",
      image: "/images/water pump.avif",
};

export const commercialWterPump = {
      description:
            "<ul> <li>Pumps for Hot Water for Water Transfer, Hot Water Circulation, solar.</li> <li>Pumps for Air-Conditioning for Water Transfer, Chilled Water Circulation.</li></ul>",
      image: "/images/water pump.avif",
};
export const hotWaterHeatPumps = {
      description:
            "<p>Midea Air Source Heat Pumps is a very versatile product to heat water. Midea Hot Water Heat Pump utilizes worldwide proven technology to capture heat from the ambient air/water and transfer it to water as per requirement. Air to water heat pump water heater is a heat recovery system that generates hot water, chilled water or a combination of hot and chilled water at one energy cost. Here we move heat from one side to the other. The cost of moving heat is very low compared to creating heat. This energy-saving device helps you to save energy costs by over 65%. Compact and sleek designed water heaters blend beautifully with interiors.</p>",
      image: "/images/hot water heat pumps.png",
      title: "Hot Water Heat Pumps",
};

export const areasOfApplication = [
      {
            image: "/images/hotel bed.png",
            title: "hotels",
      },
      {
            image: "/images/hospital-building.png",
            title: "Hospitals",
      },
      {
            image: "/images/dairy-products.png",
            title: "Diary",
      },
      {
            image: "/images/industrial.png",
            title: "Industry",
      },
      {
            image: "/images/residential.png",
            title: "Residential Building",
      },
      {
            image: "/images/swimming-pool.png",
            title: "Swimming Pools",
      },
];
export const featureAndBenefit = {
      description:
            "<ul> <li>Eco-friendly refrigerant R32</li> <li>Lower GWP 675 (GWP: Global Warming Potential)</li> <li>Zero impact on the ozone layer</li></ul>",
      image: "/images/eco.jpg",
      title: "We are friendly to the environment",
};

export const productCategory = [
      {
            id: 1,
            category: "Water & Space Heating Systems ",
            slug: "/product/water-space-heating-systems",
            subCategory: [
                  {
                        id: 1,
                        subCategoryName: "Water Heating",
                        childCategory: [
                              {
                                    id: 1,
                                    childCategoryName: "Hot Water Heat Pumps",
                                    slug: "/product/hot-water-heat-pumps",
                              },
                              {
                                    id: 2,
                                    childCategoryName:
                                          "Thermal Solar Water Heater",
                                    slug: "/product/thermal-solar-water-heater",
                              },
                              {
                                    id: 3,
                                    childCategoryName: "Swimming Pool Heating",
                                    slug: "/product/swimming-pool-heating",
                              },
                              {
                                    id: 4,
                                    childCategoryName: "Jacuzzi Heating",
                                    slug: "/product/jacuzzi-heating",
                              },
                        ],
                  },
                  {
                        id: 2,
                        subCategoryName: "Space Heating",
                        slug: "/product/space-heating",
                  },
                  {
                        id: 3,
                        subCategoryName: "Industrial Heating System",
                        childCategory: [
                              {
                                    id: 1,
                                    childCategoryName:
                                          "High Temperature Hot Water Heat Pumps",
                              },
                              {
                                    id: 1,
                                    childCategoryName:
                                          "Solar Water Heating System",
                              },
                        ],
                  },
                  {
                        id: 4,
                        subCategoryName: "OEM",
                        childCategory: [
                              {
                                    id: 1,
                                    childCategoryName:
                                          "Hot Water Storage Tanks",
                              },
                        ],
                  },
                  {
                        id: 5,
                        subCategoryName: "Accessories",
                  },
            ],
      },
      {
            id: 2,
            category: "Water and Waste",
            subCategory: [
                  {
                        id: 1,
                        subCategoryName: "Waste & Effluent Treatment",
                  },
                  {
                        id: 2,
                        subCategoryName: "Water Treatment System",
                        slug: "product/water-treatment-system",
                  },
                  {
                        id: 3,
                        subCategoryName: "Swimming Pools and SPA",
                  },
                  {
                        id: 4,
                        subCategoryName: "Water Features- & Fountains",
                  },
                  {
                        id: 5,
                        subCategoryName: "Sprinklers & Irrigation System",
                  },
            ],
      },
      {
            id: 3,
            category: "Thermax Boilers",
            slug: "https://www.thermaxglobal.com/ThermaxTCA/?tca_data_organization=5",
      },
      {
            id: 4,
            category: "water Pumps",
            slug: "/product/water-pumps",
      },
      {
            id: 5,
            category: "Compressed Air System",
      },
      {
            id: 6,
            category: "Fire Fighting System",
      },
      {
            id: 7,
            category: "Oxygen & Nitrogen Generators",
      },
      {
            id: 8,
            category: "Hospital Solutions",
            subCategory: [
                  {
                        id: 1,
                        subCategoryName: "Medical Curtain & Track System",
                  },
                  {
                        id: 2,
                        subCategoryName: "Medical Gas Pipeline System",
                        slug: "/product/medical-gas-pipeline-system",
                  },
                  {
                        id: 3,
                        subCategoryName: "Modular Operation Theatres",
                  },
                  {
                        id: 4,
                        subCategoryName: "Medical Waste Management",
                        slug: "https://www.medicalwastenepal.com/",
                  },
                  {
                        id: 5,
                        subCategoryName: "Pneuamtic Tube System",
                  },
            ],
      },
      {
            id: 9,
            category: "Others",
            subCategory: [
                  {
                        id: 1,
                        subCategoryName: "Alfa Laval",
                  },
                  {
                        id: 2,
                        subCategoryName: "Water & Oil Flow Meters",
                  },
            ],
      },
];
export const referencesImages = [
      {
            image: "/images/img-4.jpg",
            title: "Regulatory Compliance System",
      },
      {
            image: "/images/img-3.jpg",
            title: "Regulatory Compliance System",
      },
      {
            image: "/images/img-5.jpg",
            title: "Regulatory Compliance System",
      },
];
